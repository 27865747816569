import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window.location.href.includes('/webapp/oauth/cb')) {

    var hash = window.location.hash.substr(1)
    var dict_parameters : {[key:string]: string} = hash.split("&").reduce(function(result : {[key:string]: string} , param : string) {
                            var key:string , value:string;
                            [key, value] = param.split("=");
                            result[key] = value;
                            return result;
                        }, {});
    var authToken = dict_parameters['id_token']
    localStorage.setItem("jwt", authToken);
    window.location.href = decodeURIComponent(window.location.origin + dict_parameters['state'])

} else {
    ReactDOM.render(<App/>, document.getElementById('root'));
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();