import React, {Component, Fragment} from 'react';
import Utils, {HTTPError} from '../Utils';
import {
    Button,
    Container,
    Dimmer,
    Form,
    Grid,
    Header,
    Input,
    Label,
    Loader,
    Menu,
    Message,
    Modal,
    TextArea,
    Progress,
    Icon, Image, Segment
} from 'semantic-ui-react';
import ReactPlayer from 'react-player'
import * as model from "../models/Models";
import './DetailsView.css'
import {DatePickerInput} from "rc-datepicker";
import 'rc-datepicker/lib/style.css';
const host = window.location.origin;
const Highlighter = require("react-highlight-words");
const token = localStorage.getItem("jwt");
const user = Utils.parseJwt(token!);


interface detailsViewState {
    allLanguages: [],
    videoId: string,
    videoDetails?: model.VideoDetails,
    transcriptionResponseInState?: model.TranscriptionResponseInState,
    pageErrors: string[],
    current: CurrentActive,
    languageDisplayed?: string,
    currentSubtitledObject?: model.Language,
    subtitleDisplayed: boolean,
    tabActiveItem: string,
    statusUpdate?: string | null
    playing: boolean,
    buffering: boolean,
    initialBuffering: boolean,
    wordsToHighlight: string[],
    wordsToHighlightWorld: string[],
    lastPhraseEnd: number,
    doneUntilSec: number,
    finishedAtSec: number | null
    languagesFormTranslation: any[],
    languagesFormSubtitling: any[],
    requestedLanguageTranslate: string,
    requestedLanguageSubtitle: string,
    modifiedDate: Date,
    modifiedDescription?: string,
    modifiedName: string,
    videoDeleted: boolean,
    progressBar: number,
    showEditableTranscription: boolean,
    hlsForPlayer: string,
    showSubtitlesSubmittedDimmer: boolean,
    rtmpLink: string,
    downloadLink: string,
    waitingForSubtitles: boolean,
    showSubtitlesWaitingDimmer: boolean,
    showSentRtmpDimmer: boolean,
    showDidNotSavedModifies: boolean,
    subitleSelector: model.Language,
    showLanguagesMenu: boolean,
    showOptionsMenu: boolean,
    showModalModify: boolean,
    showModalDelete: boolean,
    showModalDownload: boolean,
    verticalMenuActiveItem: string,
    showDownloadWaitingDimmer: boolean,
    showWaitingSaveSRT: boolean
    tag: string,
    tags?: string[],
    hasSavedChanges: boolean,
    disabled: boolean
}


interface CurrentActive {
    currentTime: number,
    textAnnotations?: model.WordConfidence[],
    splitPhrases?: string,
}


export default class DetailsView extends Component<any, detailsViewState> {
    playerRef: any;
    languageMenuRef: any;
    socketPhrases: any;
    phraseSocketShouldClose = false;
    phrasesOffset = 0;
    customPhrases = {} as any;
    transcriptionResponseOutsideState: model.TranscriptionResponseOutsideState = {
        wordsConfidence: [],
        intervalWordsConfidence: []
    };

    constructor(props: any) {
        super(props);
        this.state = {
            allLanguages: [],
            videoId: this.props.match.params.videoId,
            pageErrors: [],
            subtitleDisplayed: false,
            current: {currentTime: 0},
            tabActiveItem: 'realtime',
            playing: false,
            buffering: false,
            initialBuffering: true,
            wordsToHighlight: [],
            wordsToHighlightWorld: [],
            lastPhraseEnd: 0,
            doneUntilSec: 0,
            finishedAtSec: null,
            languagesFormTranslation: [],
            languagesFormSubtitling: [],
            requestedLanguageTranslate: '',
            requestedLanguageSubtitle: '',
            statusUpdate: 'Receiving hls stream, please wait...',
            modifiedDate: {} as Date,
            modifiedName: '',
            videoDeleted: false,
            progressBar: 0,
            showEditableTranscription: false,
            hlsForPlayer: "",
            showSubtitlesSubmittedDimmer: false,
            rtmpLink: "",
            downloadLink: "",
            waitingForSubtitles: false,
            showSubtitlesWaitingDimmer: false,
            showDownloadWaitingDimmer: false,
            showWaitingSaveSRT: false,
            showDidNotSavedModifies: false,
            showSentRtmpDimmer: false,
            subitleSelector: {code: "", description: ""},
            showLanguagesMenu: false,
            showOptionsMenu: false,
            verticalMenuActiveItem: "",
            tag: "",
            tags: [],
            hasSavedChanges: false,
            showModalModify: false,
            showModalDelete: false,
            showModalDownload: false,
            disabled: false
        };
        this.playerRef = React.createRef();
        this.languageMenuRef = React.createRef();
        this.handleItemClick = this.handleItemClick.bind(this);
        this.loadVideoData = this.loadVideoData.bind(this);
        this.updateVideoDataPhrases = this.updateVideoDataPhrases.bind(this);
        this.phrasesSocketConnect = this.phrasesSocketConnect.bind(this);
        this.getTranscriptionsByLanguage = this.getTranscriptionsByLanguage.bind(this);
        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleModify = this.handleModify.bind(this);
        this.sendRTMP = this.sendRTMP.bind(this);
        this.handleSubtitleClick = this.handleSubtitleClick.bind(this);
        this.addTag = this.addTag.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClickOutsideLanguageMenu = this.handleClickOutsideLanguageMenu.bind(this)
    }


    hasAdminPermissions(user: any) {
        return user!.role === "admin"
    }

    handleItemClick(e: any, selected: any) {
        if (this.state.tabActiveItem === 'wholeTranscript' && this.state.showEditableTranscription && !this.state.hasSavedChanges && Object.keys(this.customPhrases).length > 0) {
            this.setState({showDidNotSavedModifies: true});
        } else
            this.setState({tabActiveItem: selected.name, playing: false, showEditableTranscription: false});
    }

    componentDidMount(): void {
        window.addEventListener("beforeunload", (e) => {
            if (this.state.tabActiveItem === "realtime" || !this.state.showEditableTranscription || this.state.hasSavedChanges) {
                return undefined;
            }

            var confirmationMessage = 'It looks like you have been editing something. '
                + 'If you leave before saving, your changes will be lost.';

            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        });
        this.checkChoices()
    }

    handleClickOutsideLanguageMenu(event: any) {
        if (this.languageMenuRef && !this.languageMenuRef.contains(event.target)) {
            this.setState({showLanguagesMenu: false, showOptionsMenu: false})
        }
    }

    phrasesSocketConnect(videoId: string, language: string, offset?: number) {
        let urlSocket = host.replace("https", "wss") + '/api/stt/streams/interviews/' + videoId + '/phrases/' + language + '?auth=' + token;
        if (offset) {
            urlSocket += `?offset=${offset}`;
        }
        this.socketPhrases = new WebSocket(urlSocket);
        this.socketPhrases.onmessage = (msg: any) => {
            let data = JSON.parse(msg.data);
            if ("finished_at_sec" in data) {
                this.setState({finishedAtSec: 1});
                this.phraseSocketShouldClose = true;
                this.socketPhrases.onmessage = (msg: any) => {
                };
            }
            if ("kafka_msg_offset" in data) {
                this.phrasesOffset = data["kafka_msg_offset"]
            }
            if (!("kafka debug" in data)) {
                this.updateVideoDataPhrases(data)
            }
        };
        this.socketPhrases.onclose = () => {
            if (!this.phraseSocketShouldClose) {
                setTimeout(() => {
                    this.phrasesSocketConnect(videoId, language, this.phrasesOffset);
                }, 1000);
            }
        };
        this.socketPhrases.onerror = (e: any) => {
            setTimeout(() => {
                this.phrasesSocketConnect(videoId, language, this.phrasesOffset);
            }, 1000);
        };
    }

    async updateVideoDataPhrases(phrase: any) {
        if (this.state.transcriptionResponseInState!.phrases.length === 0) {
            this.setState({statusUpdate: "Received first phrases, please wait...", progressBar: 100});
            await new Promise(r => setTimeout(r, 800));
        }
        let transcriptionResponseInState = this.state.transcriptionResponseInState!;
        if ("start_time" in phrase) {
            transcriptionResponseInState.phrases.push(phrase);
            transcriptionResponseInState.wholeTranscription += " " + phrase.text;
            this.setState({transcriptionResponseInState: transcriptionResponseInState});
        }
    }

    onPlay() {
        this.setState({playing: true})
    }

    onPause() {
        this.setState({playing: false})
    }

    handleSubtitleClick(language: string) {
        var subtitledObject = this.state.videoDetails!.languagesSubtitled!.find(obj => {
            return obj.code === language
        });
        if (subtitledObject && subtitledObject.source_hls_url!) {
            this.getTranscriptionsByLanguage(language);
            this.setState({hlsForPlayer: subtitledObject!.source_hls_url!, languageDisplayed: language});
        } else {
            this.setState({showSubtitlesWaitingDimmer: true});
            this.startWaitingForSubtitles(language)
        }
    }

    handleDelete() {
        Utils.deleteStream("interviews", this.state.videoDetails!.id);

        var list_lan =["en-US", "it-IT","es-ES","ja-JP","fr-FR","zh-CN","zh-HK","zh-TW"];
        for(var lan in list_lan) {
            Utils.deleteStreamSub("interviews", this.state.videoDetails!.id, list_lan[lan]);
        }
        this.setState({videoDeleted: true});
        sessionStorage.CurrentPage = 1;
        setTimeout(function () {
            window.location.href = Utils.webapp_dir + 'videos-list/';
        }, 2000);
    }

    startWaitingFor(language: string, field: string, callback: any) {
        let subtitledObject = this.state.videoDetails!.languagesSubtitled!.find(obj => {
            return obj.code === language
        });
        // @ts-ignore
        if (subtitledObject && subtitledObject[field]) {
            // @ts-ignore
            fetch(subtitledObject[field], {
                method: "GET",
            })
                .then((response: any) => {
                    if (response.status === 200) {
                        // @ts-ignore
                        callback(subtitledObject[field])
                    } else {
                        setTimeout(() =>
                            this.startWaitingFor(language, field, callback)
                        )
                    }
                });
        } else {
            if (!subtitledObject) {
                Utils.requestNewSubtitling(this.state.videoId, language).catch((error: HTTPError) => {
                    switch (error.statusCode) {
                        case 409:
                            console.log("Language already requested");
                            break;
                    }
                })
            }
            setTimeout(() => {
                Utils.getSubtitledList("interviews", this.state.videoId).then((response: any) => {
                    let videoData = this.state.videoDetails!;
                    videoData.languagesSubtitled = response.subtitled_streams as model.Language[];
                    this.setState({videoDetails: videoData});
                    this.startWaitingFor(language, field, callback);
                })
            }, 10000);
        }
    };

    startWaitingForSubtitles(language: string) {
        let callback = (link: any) => {
            this.setState({hlsForPlayer: ""}, () => this.setState({
                initialBuffering: false, showSubtitlesWaitingDimmer: false,
                hlsForPlayer: link,
                statusUpdate: "Done.", progressBar: 100
            }));
            if (this.socketPhrases) {
                this.socketPhrases.onmessage = (msg: any) => {
                };
                this.socketPhrases.close()
            }
            this.getTranscriptionsByLanguage(language);
        };
        this.startWaitingFor(language, "source_hls_url", callback);
    }

    startWaitingForDownloads(language: string) {
        let subtitledObject = this.state.videoDetails!.languagesSubtitled!.find(obj => {
            return obj.code === language
        });
        if (subtitledObject && subtitledObject.download_url) {
            window.location.href = subtitledObject.download_url;
        } else {
            Utils.getSubtitledList("interviews", this.state.videoId).then((response: any) => {
                let videoData = this.state.videoDetails!;
                videoData.languagesSubtitled = response.subtitled_streams as model.Language[];
                this.setState({videoDetails: videoData});
                let subtitledObject = videoData.languagesSubtitled.find(obj => {
                    return obj.code === language
                });
                if (subtitledObject && subtitledObject.download_url) {
                    window.location.href = subtitledObject.download_url;
                } else alert("The download is not yet available for this language. Please try again later.");
            });
        }
    }

    startWaitingForSRTs(language: string) {
        let subtitledObject = this.state.videoDetails!.languagesSubtitled!.find(obj => {
            return obj.code === language
        });
        if (subtitledObject && subtitledObject.download_url_srt) {
            window.location.href = subtitledObject.download_url_srt;
        } else {
            Utils.getSubtitledList("interviews", this.state.videoId).then((response: any) => {
                let videoData = this.state.videoDetails!;
                videoData.languagesSubtitled = response.subtitled_streams as model.Language[];
                this.setState({videoDetails: videoData});
                let subtitledObject = videoData.languagesSubtitled.find(obj => {
                    return obj.code === language
                });
                this.setState({currentSubtitledObject: subtitledObject});
                if (subtitledObject && subtitledObject.download_url_srt) {
                    window.location.href = subtitledObject.download_url_srt;
                } else alert("The SRT file is not yet available for this language. Please try again in 10 seconds.");
            });
        }
    }

    handleModify() {
        let change: model.Change = {
            name: this.state.modifiedName,
            description: this.state.modifiedDescription,
            production_date: Number(new Date(this.state.modifiedDate)),
            tags: this.state.tags!
        };
        if (!change.name || !change.production_date) {
            return false;
        }
        return Utils.updateStream('interviews', this.state.videoDetails!.id, change).then(() => {
            window.location.href = document.URL;
        })
    }

    async checkPhrases(language: string) {
        return Utils.getVideoTranscriptionPhrases("interviews", this.state.videoId, language)
            .then(() => {
            })
            .catch((e: any) => {
                switch (e.statusCode) {
                    case 426:
                        this.setState({statusUpdate: "Starting to prepare subtitled video...", progressBar: 66});
                        break;
                    case 425:
                        setTimeout(() => this.checkPhrases(language), 2000);
                        break;
                }
            });
    }

    async getTranscriptionsByLanguage(language: string) {
        Utils.getWordsInDictionary("Interviews", language).then((response: any) => {
            this.setState({wordsToHighlight: response.words});
            Utils.getWordsInDictionary("Interviews", "wr-WR").then((response: any) => {
                let wordsToHighlight = this.state.wordsToHighlight.concat(response.words);
                this.setState({wordsToHighlight: wordsToHighlight})
            });
        });
        let transcriptionResponseInState = this.state.transcriptionResponseInState!;
        this.transcriptionResponseOutsideState = {
            intervalWordsConfidence: [],
            wordsConfidence: []
        };
        transcriptionResponseInState = {
            wholeTranscription: "",
            phrases: []
        };
        this.setState({
            transcriptionResponseInState: transcriptionResponseInState,
            languageDisplayed: language,
            subtitleDisplayed: false
        });
        let subtitledObject = this.state.videoDetails!.languagesSubtitled!.find(obj => {
            return obj.code === language
        });
        this.setState({currentSubtitledObject: subtitledObject});
        Utils.getVideoTranscriptionPhrases("interviews", this.state.videoId, language)
            .then((response: any) => {
                this.setState({finishedAtSec: 1});
                let transcriptionResponse = response.phrases as model.Phrase[];
                transcriptionResponse.forEach(phrase => {
                    this.updateVideoDataPhrases(phrase)
                });
            }).catch((e: any) => {
            switch (e.statusCode) {
                case 426:
                    this.phrasesSocketConnect(this.state.videoId, language);
                    break;
                case 425:
                    setTimeout(() => this.getTranscriptionsByLanguage(language), 2000);
                    break;
            }
        });
    }

    disabledMenu(checkSub: boolean) {
        if(checkSub === true) {
            this.setState({disabled: true})
        } else {
            console.log("DISABILITO WHOLETRANSCRIPT")
        }
    }

    checkChoices() {
    Utils.getVideoDetails("interviews", this.state.videoId)
            .then((response: model.VideoDetails) => {
                if(response.noSubtitled === false) {
                    console.log("SOTTOTITOLI Sì");
                    this.disabledMenu(response.noSubtitled);
                    this.loadVideoData();
                } else {
                    console.log("SOTTOTITOLI NO");
                    this.disabledMenu(response.noSubtitled);
                    if(response.now_pushing) {
                        this.loadVideoDataNoSub()
                    } else {
                        this.loadVideoData()
                    }
                }
            })
            .catch((e: any) => {
                let pageErrors = this.state.pageErrors;
                switch (e.statusCode) {
                    case 404:
                        pageErrors.push('Not existent ID');
                        this.setState({pageErrors: pageErrors});
                        break;
                    case 425:
                        console.log('SONO IN 425, SETTO TIMEOUT');
                        setTimeout(() => this.checkChoices(), 2000);
                        break;
                }
            });
    }

    startWaitingForHls(language: string, field: string, callback: any) {
        let subtitledObject = this.state.videoDetails!.languagesSubtitled!.find(obj => {
            return obj.code === language
        });
        console.log('SONO IN WAIT PER HLS');
        // @ts-ignore
        if (subtitledObject && subtitledObject[field]) {
            // @ts-ignore
            fetch(subtitledObject[field], {
                method: "GET",
            })
                .then((response: any) => {
                    if (response.status === 200) {
                        // @ts-ignore
                        callback(subtitledObject[field])
                    } else {
                        setTimeout(() =>
                            this.startWaitingForHls(language, field, callback)
                        )
                        console.log('ESEGUO TIMEOUT');
                    }
                });
        } else {
            setTimeout(() => {
                Utils.getSubtitledList("interviews", this.state.videoId).then((response: any) => {
                    let videoData = this.state.videoDetails!;
                    videoData.languagesSubtitled = response.subtitled_streams as model.Language[];
                    this.setState({videoDetails: videoData});
                    this.startWaitingForHls(language, field, callback);
                })
            }, 10000);
        }
    };

    startWaitingForStream(language: string) {
        let callback = (link: any) => {
            this.setState({hlsForPlayer: ""}, () => this.setState({
                initialBuffering: false, showSubtitlesWaitingDimmer: false,
                hlsForPlayer: link,
                statusUpdate: "Done.", progressBar: 100
            }));
            if (this.socketPhrases) {
                this.socketPhrases.close()
            }
        };
        console.log('richiamo la waiting for HLS');
        this.startWaitingForHls(language, "source_hls_url", callback);
    }

    loadVideoDataNoSub() {
        return Utils.getVideoDetails("interviews", this.state.videoId)
            .then((response: model.VideoDetails) => {
                Utils.getAvailableLanguages().then((allLanguages: any) => {
                    this.setState({allLanguages: allLanguages["languages"]})
                });
                //setTimeout(() => this.checkPhrases(response.language), 2000);
                return new Promise((resolve, reject) => {
                    this.setState({
                        videoDetails: response,
                        hlsForPlayer: response.source_hls_url!,
                        tags: response.tags
                    }, () => {
                        resolve();
                    });
                })
                    .then(() => {
                        return response;
                    });
            })
            .then((response: any) => {
                this.startWaitingForStream(response.language);
                this.setState({
                    statusUpdate: "Uploading video without subtitles, please wait...", progressBar: 33,
                    modifiedDescription: this.state.videoDetails!.description,
                    modifiedName: this.state.videoDetails!.name,
                    modifiedDate: new Date(this.state.videoDetails!.production_date)
                });
            })
            .catch((e: any) => {
                let pageErrors = this.state.pageErrors;
                switch (e.statusCode) {
                    case 404:
                        pageErrors.push('Not existent ID');
                        this.setState({pageErrors: pageErrors});
                        break;
                    case 425:
                        console.log('SONO IN 425, SETTO TIMEOUT');
                        setTimeout(() => this.loadVideoDataNoSub().catch(() => {
                        }), 2000);
                        break;
                }
            });
    }

    loadVideoData() {
        return Utils.getVideoDetails("interviews", this.state.videoId)
            .then((response: model.VideoDetails) => {
                Utils.getAvailableLanguages().then((allLanguages: any) => {
                    this.setState({allLanguages: allLanguages["languages"]})
                });
                setTimeout(() => this.checkPhrases(response.language), 2000);
                return new Promise((resolve, reject) => {
                    this.setState({
                        videoDetails: response,
                        hlsForPlayer: response.source_hls_url!,
                        tags: response.tags
                    }, () => {
                        resolve();
                    });
                })
                    .then(() => {
                        return response;
                    });
            })
            .then((response: any) => {
                this.startWaitingForSubtitles(response.language);
                this.setState({
                    statusUpdate: "Starting transcription, please wait...", progressBar: 33,
                    modifiedDescription: this.state.videoDetails!.description,
                    modifiedName: this.state.videoDetails!.name,
                    modifiedDate: new Date(this.state.videoDetails!.production_date)
                });
            })
            .catch((e: any) => {
                let pageErrors = this.state.pageErrors;
                switch (e.statusCode) {
                    case 404:
                        pageErrors.push('Not existent ID');
                        this.setState({pageErrors: pageErrors});
                        break;
                    case 425:
                        setTimeout(() => this.loadVideoData().catch(() => {
                        }), 2000);
                        break;
                }
            });
    }

    getUrlFlag(countryCode: string) {
        return Utils.webapp_dir + countryCode.toLowerCase().split("-")[1] + ".svg"
    }

    sendRTMP() {
        Utils.sendRTMP(this.state.videoId, this.state.languageDisplayed!, this.state.rtmpLink).then(() => {
                this.setState({showSentRtmpDimmer: true})
            }
        ).catch(() => {
            alert("Error with the provided RTMP.")
        })
    }

    addTag() {
        let {tag} = this.state;
        if (tag) {
            let tags = this.state.tags!;
            let tagsList = tag.split(" ");
            let newTagsList = tagsList.map((element: string) => {
                if (element[0] === "#") {
                    return element
                } else return "#" + element
            });
            tags = tags.concat(newTagsList);
            tags = tags.filter((v, i, a) => a.indexOf(v) === i);
            tags = tags.filter(e => e !== "#");
            this.setState({tags: tags, tag: ''});
        }
    }

    deleteTag(tag: any) {
        let tags = this.state.tags!.filter(e => e !== tag);
        this.setState({tags: tags});
    }

    renderDeleteCompleted() {
        return (
            <Grid textAlign={"center"}>
                <Grid.Row>
                </Grid.Row>
                <Grid.Row>
                    <Header as='h2'>
                        Video deleted - Redirecting on videos list...
                    </Header>
                </Grid.Row>
            </Grid>
        )
    }

    renderModalDownload() {
        return (
            <Button active={false} fluid style={{"borderRight": "0.1em solid #fff"}}
                    onClick={() =>
                        this.startWaitingForDownloads(this.state.languageDisplayed!)}
            >
                Download Video
            </Button>
        )
    }

    renderModalDownloadSRT() {
        return (
            <Button active={false} fluid style={{"borderRight": "0.1em solid #fff"}}
                    onClick={() =>
                        this.startWaitingForSRTs(this.state.languageDisplayed!)}
            >
                Download SRT
            </Button>
        )
    }

    renderModalReprocessVideo() {
        return (
            <Button active={false} fluid style={{"borderRight": "0.1em solid #fff"}}
                    onClick={() =>
                        Utils.requestNewSubtitling(this.state.videoId, this.state.languageDisplayed!)
                            .then(() => {
                                this.state.videoDetails!.languagesSubtitled! = [];
                                this.handleSubtitleClick(this.state.languageDisplayed!);
                                this.setState({showSubtitlesWaitingDimmer: true})
                            })
                            .catch((error: HTTPError) => {
                                switch (error.statusCode) {
                                    case 409:
                                        alert("Transcriptions in current language are already up-to-date.");
                                        console.log("Language already requested");
                                        break;
                                }
                            })
                    }

            >
                Apply phrases changes
            </Button>
        )
    }

    renderLanguagesMenu() {
        return (
            <div ref={ref => this.languageMenuRef = ref}>
                <Menu vertical style={{"position": "absolute"}} >
                    <br/>
                    <Header as={"h8"}> Subtitles Language</Header>
                    <br/>
                    <br/>
                    {this.state.allLanguages.map((language: any, key) =>
                        <Menu.Item disabled={this.state.disabled}
                            onClick={() => this.handleSubtitleClick(language.code)}
                            active={this.state.languageDisplayed === language.code}
                        >
                            <Grid style={{"padding-bottom": "0rem", "padding-top": "0rem"}}>
                                <Grid.Row columns={2} style={{"padding-bottom": "0rem", "padding-top": "0rem"}}>
                                    <Grid.Column style={{"padding-bottom": "0rem", "padding-top": "0rem"}}>
                                        <Image src={this.getUrlFlag(language.code)} size={"mini"} bordered/>
                                    </Grid.Column>
                                    <Grid.Column>
                                        {language.description}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        )
    }

    renderOptionsMenu() {
        return (
            <div ref={ref => this.languageMenuRef = ref}>
                <Modal open={this.state.showModalModify}
                       onClose={() => {
                           document.addEventListener("mousedown", this.handleClickOutsideLanguageMenu, false);
                           this.setState({showModalModify: false})
                       }} closeIcon>
                    <Header content='Edit Details'
                            style={{"fontWeight": "400", "font-size": "large"}}/>
                    <Modal.Content>
                        <Form>
                            <Form.Group inline>
                                <Form.Field
                                    required
                                    control={Input}
                                    label='Title'
                                    placeholder='Title'
                                    defaultValue={this.state.videoDetails!.name}
                                    style={{"margin-right": "1em"}}
                                    onChange={(e: any) => {
                                        this.setState({modifiedName: e.target.value})
                                    }}
                                />
                                <DatePickerInput
                                    onChange={(e: any) => {
                                        this.setState({modifiedDate: e})
                                    }}
                                    defaultValue={new Date(this.state.videoDetails!.production_date)}
                                    value={this.state.modifiedDate}
                                    className='my-react-datepicker'
                                />
                            </Form.Group>
                            <Form.Field
                                control={TextArea}
                                label='Description'
                                placeholder='Description'
                                defaultValue={this.state.videoDetails!.description}
                                onChange={(e: any) => {
                                    this.setState({modifiedDescription: e.target.value})
                                }
                                }
                            />
                            <Form.Input
                                icon='tags'
                                iconPosition='left'
                                label={'Tags'}
                                placeholder='Enter tags'
                                width={8}
                                value={this.state.tag}
                                onChange={(e: any, inputChange: any) => {
                                    this.setState({tag: inputChange.value});
                                }}
                            />
                            <Form.Button
                                width={2}
                                content={'Add'}
                                onClick={this.addTag}
                            />
                            <div>
                                {this.state.tags!.map((tag) => (
                                    <Label style={{"backgroundColor": "#e5dfd9", "color": "#313131"}} key={tag} value={tag}
                                           onClick={() => this.deleteTag(tag)}>
                                        {tag}
                                        <Icon name='delete'/>
                                    </Label>
                                ))}
                            </div>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleModify}>
                            Save Changes
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal size={"mini"}
                       open={this.state.showModalDelete}
                       onClose={() => {
                           document.addEventListener("mousedown", this.handleClickOutsideLanguageMenu, false);
                           this.setState({showModalDelete: false})
                       }}
                       closeIcon>
                    <Modal.Header content='Delete video'/>
                    <Modal.Content>
                        The video will be deleted. Continue?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.handleDelete}>
                            Delete video
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Menu vertical style={{"position": "absolute"}}>
                    <br/>
                    <Header as={"h8"}> Other Options</Header>
                    <br/>
                    <br/>
                    <Menu.Item>
                        <Button onClick={()=>{
                            document.removeEventListener("mousedown", this.handleClickOutsideLanguageMenu, false);
                            this.setState({showModalModify: true})
                        }} fluid style={{"borderRight": "0.1em solid #fff"}}>
                            Edit Details
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        {this.renderModalDownload()}
                    </Menu.Item>
                    <Menu.Item>
                        {this.renderModalDownloadSRT()}
                    </Menu.Item>
                    {this.state.currentSubtitledObject && this.state.currentSubtitledObject.is_stream_old?
                    <Menu.Item>
                        {this.renderModalReprocessVideo()}
                    </Menu.Item>
                    :null}
                    <Menu.Item>
                        <Input
                            placeholder='Insert a valid RTMP'
                            onChange={(e: any) =>
                                this.setState({rtmpLink: e.target.value})
                            }/>
                        <Button
                            fluid
                            onClick={this.sendRTMP}>
                            Stream RTMP
                        </Button>
                    </Menu.Item>
                    <Menu.Item>
                        <Button onClick={()=>{
                            document.removeEventListener("mousedown", this.handleClickOutsideLanguageMenu, false);
                            this.setState({showModalDelete: true})
                        }}

                                className={"button2"} fluid style={{
                            "background-color": "rgb(230, 101, 101)"
                        }}>
                            Delete video
                        </Button>
                    </Menu.Item>
                </Menu>
            </div>
        )
    }

    renderPlayer() {
        return (
            <div>
                <Dimmer.Dimmable dimmed={this.state.buffering}>
                    <Dimmer active={this.state.buffering}> <Loader>{'Buffering...'}</Loader> </Dimmer>
                    <ReactPlayer
                        ref={this.playerRef}
                        onPlay={this.onPlay}
                        onPause={this.onPause}
                        url={this.state.hlsForPlayer}
                        controls={!this.state.buffering}
                        width="100%"
                        height="auto"
                        playing={this.state.playing}
                    />
                </Dimmer.Dimmable>
                <Grid>
                <Grid.Row textAlign={'left'} column={2} style={{"paddingBottom": "0em", "height": "3em!important"}}>
                    <Grid.Column textAlign={'left'} width={12} floated={'left'}>
                        {this.state.currentSubtitledObject && this.state.currentSubtitledObject.is_stream_old?
                            <Message warning attached='bottom'>
                                <Icon name='warning' />
                                Manual changes to phrases pending. Please reprocess the video.
                            </Message>:
                            null
                        }

                        <Header as={"h5"}>
                            {Utils.fromTimestampToParsedDate(this.state.videoDetails!.production_date)}
                        </Header>
                        <Label.Group>
                            {
                                this.state.videoDetails!.tags!.map((tag: string) => (
                                    <Label
                                        style={{"backgroundColor": "#e5dfd9", "color": "#313131"}}
                                        key={tag + 'label'}
                                        value={tag}
                                    >
                                        {tag}
                                    </Label>))
                            }
                        </Label.Group>
                        <Header as={"h3"}>
                            Description
                        </Header>
                        <Container textAlign={"left"}>
                            {this.state.videoDetails!.description}
                        </Container>
                    </Grid.Column>
                    <Grid.Column textAlign={'right'} width={4} floated={'right'}>
                        <Menu vertical style={{"position": "absolute"}} floated={'right'}>
                            <Menu.Item
                                active={this.state.showLanguagesMenu}
                                onClick={() => {
                                    document.addEventListener("mousedown", this.handleClickOutsideLanguageMenu, false);
                                    this.setState({
                                        showLanguagesMenu: !this.state.showLanguagesMenu,
                                        showOptionsMenu: false
                                    })
                                }
                                }
                            >
                                <Icon name='closed captioning'/>
                                Subtitles Language
                            </Menu.Item>
                            <Menu.Item
                                active={this.state.showOptionsMenu}
                                onClick={() => {
                                    document.addEventListener("mousedown", this.handleClickOutsideLanguageMenu, false);
                                    this.setState({
                                        showOptionsMenu: !this.state.showOptionsMenu,
                                        showLanguagesMenu: false
                                    })
                                }
                                }
                            >
                                <Icon name='options'/>
                                Other Options
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
                <Modal
                    open={this.state.showSubtitlesWaitingDimmer}
                    onClose={() => {
                        this.setState({showSubtitlesWaitingDimmer: false})
                    }}
                    size='small'
                    closeOnDimmerClick={false}
                >
                    <Header icon='closed captioning' content='The request is on progress. The stream will start as soon as it will be available. In the meantime
                                        you can close this panel and continue to browse the webapp.'/>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.setState({showSubtitlesWaitingDimmer: false})
                        }} inverted>
                            <Icon name='close'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.state.showSentRtmpDimmer}
                    onClose={() => {
                        this.setState({showSentRtmpDimmer: false})
                    }}
                    size='small'
                >
                    <Header icon='closed captioning'
                            content='Stream correctly sent to the provided RTMP link.'/>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.setState({showSentRtmpDimmer: false})
                        }} inverted>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

    renderRealTime() {
        return (
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}>
                    </Grid.Column>
                    <Grid.Column key={'renderplayer'} width={10}>
                        {this.renderPlayer()}
                    </Grid.Column>
                    <Grid.Column width={3} textAlign={"center"}>
                        {this.state.showLanguagesMenu ? this.renderLanguagesMenu() : null}
                        {this.state.showOptionsMenu ? this.renderOptionsMenu() : null}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    renderEditableTranscription() {
        return (
            <Container>
                <Modal
                    open={this.state.showDidNotSavedModifies}
                    onClose={() => {
                        this.setState({showDidNotSavedModifies: false})
                    }}
                    size='small'
                    closeOnDimmerClick={false}
                >
                    <Header icon='closed captioning'
                            content="There are unsaved changes. Are you sure you want to leave this page?"/>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.customPhrases = {};
                            this.setState({showDidNotSavedModifies: false, tabActiveItem: "realtime"})
                        }} inverted>
                            <Icon name='checkmark'/> Yes
                        </Button>
                        <Button onClick={() => {
                            this.setState({showDidNotSavedModifies: false})
                        }} inverted>
                            <Icon name='close'/> No
                        </Button>
                    </Modal.Actions>
                </Modal>
                <Modal
                    open={this.state.showWaitingSaveSRT}
                    onClose={() => {
                        this.setState({showWaitingSaveSRT: false})
                    }}
                    size='small'
                    closeOnDimmerClick={true}
                >
                    <Header icon='closed captioning' content='the request is being processed.'/>
                </Modal>
                {this.hasAdminPermissions(user)?
                    !this.state.showEditableTranscription ?
                    <Button
                        onClick={() => {
                            if (this.state.finishedAtSec) {
                                this.setState({
                                    showEditableTranscription: true,
                                    hasSavedChanges: false
                                });
                            } else alert("The stream is being processed, therefore is impossible to edit the phrases.")
                        }}>
                        Edit Subtitles
                    </Button>
                    :
                    <Button
                        onClick={() => {
                            if(Object.keys(this.customPhrases).length > 0){
                                this.setState({showWaitingSaveSRT: true});
                                Utils.updatePhraseCustomText("interviews", this.state.videoId, this.state.languageDisplayed!, this.customPhrases)
                                    .then(() => {
                                        this.setState({ hasSavedChanges: true, showEditableTranscription: false});
                                        this.state.videoDetails!.languagesSubtitled! = [];
                                        Utils.getSubtitledList("interviews", this.state.videoId).then((response: any) => {
                                            let videoData = this.state.videoDetails!;
                                            videoData.languagesSubtitled = response.subtitled_streams as model.Language[];
                                            this.setState({videoDetails: videoData});
                                            let subtitledObject = videoData.languagesSubtitled.find(obj => {
                                                return obj.code === this.state.languageDisplayed
                                            });
                                            this.setState({currentSubtitledObject: subtitledObject});
                                        }).then(()=>
                                            this.setState({showWaitingSaveSRT: false})
                                        );
                                        let transcriptionResponseInState = this.state.transcriptionResponseInState!;
                                        Object.keys(this.customPhrases).forEach((value:string)=>{
                                            transcriptionResponseInState.phrases[this.customPhrases[value].key].custom_text = this.customPhrases[value].custom_text;
                                        });
                                        this.setState({transcriptionResponseInState: transcriptionResponseInState})
                                    })
                            }
                            else alert("There are no changes.")
                        }}>

                        Save Changes
                    </Button>
                    : null
                }
                <Grid textAlign={"left"} width={14} style={{"white-space": "pre-line"}}>
                    <Grid.Row columns={2}>
                        <Grid.Column width={2} textAlign={"center"} style={{"fontWeight": "700"}}>
                            Start - End
                        </Grid.Column>
                        <Grid.Column width={14} style={{"fontWeight": "700"}}>
                            Text
                        </Grid.Column>
                    </Grid.Row>
                    {this.state.transcriptionResponseInState!.phrases.map((value, key) =>
                        <Grid.Row columns={2}
                                  style={{"padding-top": 0, "padding-bottom": "0.5em"}}>
                            <Grid.Column width={2} textAlign={"center"}>
                                {
                                    new Date(value.start_time * 1000).toISOString().substr(11, 8)
                                }
                                {" - "}
                                {
                                    new Date(value.end_time * 1000).toISOString().substr(11, 8)
                                }
                            </Grid.Column>
                            <Grid.Column width={14}>
                                <Highlighter
                                    highlightStyle={{
                                        color: '#0003d1',
                                        fontWeight: 'bold',
                                        backgroundColor: '#ffffff'
                                    }}
                                    onInput={(e: any) => {
                                        this.customPhrases[value.id] = {
                                            "custom_text": e.currentTarget.innerText,
                                            "key": key
                                        };
                                    }}
                                    contentEditable={this.hasAdminPermissions(user!) && this.state.finishedAtSec != null && this.state.showEditableTranscription}
                                    searchWords={this.state.wordsToHighlight}
                                    autoEscape={true}
                                    textToHighlight={value.custom_text ? value.custom_text : value.text}
                                    style={{"paddingRight": "3px"}}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )}
                </Grid>
            </Container>
        )
    }

    renderPlayerTranscription() {
        return (
            <div style={{"position": "fixed", "margin-left": "120px", "width": "25%"}}>
                <Dimmer.Dimmable dimmed={this.state.buffering}>
                    <Dimmer active={this.state.buffering}> <Loader>{'Buffering...'}</Loader> </Dimmer>
                    <ReactPlayer
                        ref={this.playerRef}
                        onPlay={this.onPlay}
                        onPause={this.onPause}
                        url={this.state.hlsForPlayer}
                        controls={!this.state.buffering}
                        width="100%"
                        height="auto"
                        playing={this.state.playing}
                    />
                </Dimmer.Dimmable>
                <Grid>
                <Grid.Row textAlign={'left'} column={2} style={{"paddingBottom": "0em", "height": "3em!important"}}>
                    <Grid.Column textAlign={'left'} width={12} floated={'left'}>
                        {this.state.currentSubtitledObject && this.state.currentSubtitledObject.is_stream_old?
                            <Message warning attached='bottom'>
                                <Icon name='warning' />
                                Manual changes to phrases pending. Please reprocess the video.
                            </Message>:
                            null
                        }

                        <Header as={"h5"}>
                            {Utils.fromTimestampToParsedDate(this.state.videoDetails!.production_date)}
                        </Header>
                    </Grid.Column>
                    <Grid.Column textAlign={'right'} width={4} floated={'right'}>
                        <Menu vertical style={{"position": "absolute", "border": "0"}} floated={'right'}>
                            {this.state.currentSubtitledObject && this.state.currentSubtitledObject.is_stream_old?
                            <Menu.Item>
                                {this.renderModalReprocessVideo()}
                            </Menu.Item>
                            :null}
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
                <Modal
                    open={this.state.showSubtitlesWaitingDimmer}
                    onClose={() => {
                        this.setState({showSubtitlesWaitingDimmer: false})
                    }}
                    size='small'
                    closeOnDimmerClick={false}
                >
                    <Header icon='closed captioning' content='The request is on progress. The stream will start as soon as it will be available. In the meantime
                                        you can close this panel and continue to browse the webapp.'/>
                    <Modal.Actions>
                        <Button onClick={() => {
                            this.setState({showSubtitlesWaitingDimmer: false})
                        }} inverted>
                            <Icon name='close'/> Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

    renderTranscription() {
        return (
            <Container style={{'padding': '1em', 'margin': '0em'}} textAlign={"center"}>
            <Grid>
                <Grid.Row columns={3}>
                    <Grid.Column width={8}>
                        {this.renderEditableTranscription()}
                    </Grid.Column>
                    <Grid.Column key={'renderplayertran'} width={8}>
                        {this.renderPlayerTranscription()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            </Container>
        )
    }

    conditionedRender() {
        const {tabActiveItem} = this.state;
        if (!this.state.initialBuffering) {
            return (
                <Fragment>
                    <Grid>
                        <Grid.Row columns={2} style={{"padding-top": "0", "padding-bottom": "0"}}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={14} textAlign="left">
                                <Button
                                    onClick={() => {
                                        window.location.href = Utils.webapp_dir + "videos-list";
                                    }}>
                                    Back
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Header as='h2' style={{"margin": "14px"}}>
                        {this.state.videoDetails!.name}
                    </Header>
                    <Grid>
                        <Grid.Row columns={1} centered>
                            <Grid.Column width={12}>
                                <Menu attached='top' tabular style={{"borderRadius": "0!important"}}>
                                    <Menu.Item name='realtime' active={tabActiveItem === 'realtime'}
                                               onClick={this.handleItemClick}/>
                                    <Menu.Item name='wholeTranscript' active={tabActiveItem === 'wholeTranscript'}
                                               onClick={this.handleItemClick} disabled={this.state.disabled}/>
                                </Menu>
                                {this.state.tabActiveItem === 'realtime' ? this.renderRealTime() : null}
                                {this.state.tabActiveItem === 'wholeTranscript' ? this.renderTranscription() : null}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Fragment>
            )
        } else if (this.state.pageErrors.length > 0) {
            return this.state.pageErrors.map((error) => <Message negative>{error}</Message>)
        } else return (
            <Container>
                <Grid>
                    <Grid.Row columns={3}>
                        <Grid.Column width={4}/>
                        <Grid.Column width={8}>
                            <Progress percent={this.state.progressBar} indicating progress={"percent"}/>
                        </Grid.Column>
                        <Grid.Column width={4}/>
                    </Grid.Row>
                    <Grid.Row>
                        <Dimmer active inverted><Loader size='large'>{this.state.statusUpdate || "Loading..."}</Loader></Dimmer>
                    </Grid.Row>
                </Grid>
            </Container>

        )
    }

    render() {
        return (
            <Container textAlign='center'>
                {this.state.videoDeleted ? this.renderDeleteCompleted() : this.conditionedRender()}
            </Container>
        )
    }

}