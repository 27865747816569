import React, {Component} from 'react';
import {Card, Container, Pagination, Search, Grid, Select} from 'semantic-ui-react';
import Utils from '../Utils';
import * as model from '../models/dbVideoModel';
import Truncate from "react-truncate";
import './VideoList.css'

interface uploadViewState {
    video: model.VideoDetails,
    pageErrors: string[],
    videoData: model.VideoDetails[],
    allDownloadedVideos?: any,
    videoDisplayed: model.VideoDetails[],
    loading: boolean,
    itemsPerPage: number,
    activePage: number,
    searchValue: string,
    results: model.VideoDetails[],
    details: model.VideoDetails
}

//INITIALIZE CURRENTPAGE
if (typeof sessionStorage.CurrentPage === 'undefined') {
    sessionStorage.CurrentPage = 1
}

const itemsPerPageList = [
    {
        key: '9',
        text: '9',
        value: 9,
    },
    {
        key: '18',
        text: '18',
        value: 18,
    },
    {
        key: '45',
        text: '45',
        value: 45,
    },
    {
        key: '90',
        text: '90',
        value: 90,
    }
];


class CardCustom extends Component<{ video: model.VideoDetails, onClickDetails: (event: any, data: any) => void }> {

    render() {

        let {video} = this.props;

        return (
            <Card className={"Card-Video-Style"}
                      link={true}
                      fluid
                      onClick={this.props.onClickDetails}
                      style={
                          {backgroundImage: "url(" + video.thumbnail_url + ")", backgroundSize: "cover", height:"17em"}
                      }
                  as={"i"}
            >
                <Card.Content />
            <Card.Content extra textAlign={"left"} style={{height: "6em"}}>
                <Card.Header style={{'color':'#e5dfd9'}}>
                    <Truncate lines={1} ellipsis={<span>...</span>} color={'white!important'}>
                        {video.name}
                    </Truncate>
                </Card.Header>
                <Card.Meta style={{'color':'#e5dfd9'}}>
                    <span className='title'>{video.production_date? Utils.fromTimestampToParsedDate(video.production_date) : 'Date missing'}</span>
                    <span className='language'>{video.language}</span>
                </Card.Meta>
                <Card.Description style={{'color':'#e5dfd9'}}>
                    <Truncate lines={1} ellipsis={<span>...</span>}>
                        {video.description ? video.description : 'No description available.'}
                    </Truncate>
                </Card.Description>
            </Card.Content>
            </Card>)
    }
}


export default class VideoList extends Component<any, uploadViewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            video: {} as model.VideoDetails,
            pageErrors: [],
            loading: false,
            videoData: [] as model.VideoDetails[],
            itemsPerPage: 9,
            videoDisplayed: [] as model.VideoDetails[],
            searchValue: '',
            results: [],
            details: {} as model.VideoDetails,
            activePage: 1
        };
        this.handleClickOnDetails = this.handleClickOnDetails.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handlePaginationChange = this.handlePaginationChange.bind(this);
        this.handleItemsPerPage = this.handleItemsPerPage.bind(this)
    }

    componentDidMount() {
        return Utils.getVideoList().then((responseStreams: any) => {
            this.setState({videoData: responseStreams.streams});
            if (typeof sessionStorage.CurrentPage !== 'undefined') {
                let displayedNow = this.state.videoData.slice(this.state.itemsPerPage*(sessionStorage.CurrentPage - 1), (this.state.itemsPerPage*sessionStorage.CurrentPage));
                this.setState({videoDisplayed:displayedNow});
            } else {
                this.setState({videoDisplayed: responseStreams.streams.slice(0, this.state.itemsPerPage)});
            }
        })
    }

    handleClickOnDetails(videoId: any) {
        console.log('CurrentPage pre-redirect: '+sessionStorage.CurrentPage);
        window.location.href=  Utils.webapp_dir + 'details/' + videoId;
    };

    handleItemsPerPage(e: any, {value}:any) {
        this.setState({itemsPerPage: value, videoDisplayed: this.state.videoData.slice(0, value)});
        sessionStorage.CurrentPage = 1;
    };

    handleSearchChange(event: any) {
        this.setState({searchValue: event.target.value});
        return Utils.filterVideosBy(event.target.value).then((responseStreams: any)=>{
            this.setState({videoData: responseStreams.streams});
            this.setState({videoDisplayed: responseStreams.streams.slice(0, this.state.itemsPerPage)})
        });
    }

    handlePaginationChange(e:any, { activePage }:any) {
            this.setState({ activePage: activePage });
            sessionStorage.CurrentPage= activePage;
            let displayedNow = this.state.videoData.slice(this.state.itemsPerPage*(sessionStorage.CurrentPage - 1), (this.state.itemsPerPage*sessionStorage.CurrentPage));
            this.setState({videoDisplayed:displayedNow});
    }


    renderList() {

        return (

            <Container>
                <Search fluid
                        onSearchChange={this.handleSearchChange}
                        style={{"marginTop": "2em", "marginBottom": "2em"}}
                />
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Container>
                            <Card.Group itemsPerRow={3} stackable centered style={{"paddingRight":"5em", "paddingLeft":"5em", "overflow":"visible"}}>
                                {this.state.videoDisplayed.map((videoObj: model.VideoDetails) =>
                                    <CardCustom
                                        key={videoObj.id}
                                        video={videoObj}
                                        onClickDetails={()=>this.handleClickOnDetails(videoObj.id)}
                                    />
                                )}
                            </Card.Group>
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3} widths={"equal"}>
                        <Grid.Column width={2} />
                        <Grid.Column width={12}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={3}/>
                                    <Grid.Column width={10}>
                                    {this.state.videoData?
                                    <Pagination
                                        pointing
                                        secondary
                                        activePage={sessionStorage.CurrentPage}
                                        onPageChange={this.handlePaginationChange}
                                        totalPages={this.state.videoData.length % this.state.itemsPerPage === 0?
                                            Math.floor(this.state.videoData.length/this.state.itemsPerPage):
                                            Math.floor(this.state.videoData.length/this.state.itemsPerPage)+1
                                        }
                                    />: null}
                                    </Grid.Column>
                                    <Grid.Column width={3} floated={'right'}>
                                    <Select
                                        options={itemsPerPageList}
                                        onChange={this.handleItemsPerPage}
                                        placeholder={"Items per page"}
                                    />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={2} />
                    </Grid.Row>
                </Grid>
            </Container>)
    }

    render() {
        return (
            <Container>
                {this.renderList()}
            </Container>

        )
    }

}