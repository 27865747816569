import * as model from "./models/Models";

export class HTTPError extends Error {
    statusCode: number;

    constructor(code: number) {
        super("error in request. Status code:" + code.toString());
        this.statusCode = code;
    }
}

export default class Utils {
    static host = window.location.host.includes("localhost") ? "http://localhost": window.location.origin;
    static webapp_dir = process.env.NODE_ENV === 'development' ? "/" : "/webapp/";

    static init() {
    }

    static STREAM_HLS_URL(category: string, id: string){
        let hls_path = "streams/" + category.toLowerCase() + "/" + id + "/index.m3u8";
        return Utils.host + hls_path;
    }

    static parseJwt(token: string) {
        if (!token) {
            return {
                email: '',
                name: '',
                family_name: '',
                display_name: '',
                role: '',
                signature: '',
                expirationDate: '',
                identity: ''
            }
        }
        let base64Url = token.toString().split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        let parsedToken = JSON.parse(jsonPayload);
        let role = "admin" //TODO need fix
        let email = parsedToken.mail;
        let parsed: model.AuthJWT = {
            role: role,
            email: email,
            name: parsedToken.name,
            family_name: parsedToken.family_name,
            display_name: '',//parsedToken.user_claims.display_name, TODO need fix
            signature: '', //parsedToken.user_claims.signature, TODO need fix
            expirationDate: parsedToken.exp,
            identity: parsedToken.sub
        };
        return parsed
    }

    static async fetchFromServer(url: string, method: string, body?: any) {
        return fetch(Utils.host + url, {
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }),
            method: method,
            body: body ? JSON.stringify(body) : null
        }).then((response: any) => {
            if (response.status === 401) {
                window.location.href = window.location.origin + "/api/auth/login?final_redirect=" + window.location.pathname;
            }
            if (response.status >= 400 && response.status!==422) {
                throw new HTTPError(response.status)
            }
            if (response.status === 422) {
                throw new HTTPError(response.status)
            }
            if (response.status === 200 || response.status === 201) {
                return response.json()
            }
            else {
                console.error("unknown error: " + response);
                throw new HTTPError(response.status)
            }
        });
    }

    static getDateFromTimestamp(timestamp: number) {
        let m = new Date(Number(timestamp));
        let seconds = m.getUTCSeconds() < 10 ?  '0' + m.getUTCSeconds() : m.getUTCSeconds();
        let minutes = m.getUTCMinutes() < 10 ?  '0' + m.getUTCMinutes() : m.getUTCMinutes();
        let hours = m.getUTCHours() < 10 ?  '0' + m.getUTCHours() : m.getUTCHours();
        let month = (m.getUTCMonth() + 1) < 10 ? '0' + (m.getUTCMonth() + 1) : (m.getUTCMonth() + 1);
        let year = m.getUTCFullYear();
        let day = m.getUTCDate();
        return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
    }

    static getAvailableDictionaries() {
        let url = '/api/stt/interviews/dictionaries';
        return Utils.fetchFromServer(url, "get")
    }

    static getAvailableLanguages() {
        let url = '/api/stt/languages';
        return Utils.fetchFromServer(url, "get")
    }

    static requestNewTranslation(videoId: string, language: string) {
        let url = '/api/stt/streams/interviews/' + videoId + '/translations/' + language;
        return Utils.fetchFromServer(url, "post")
    }

    static requestNewSubtitling(videoId: string, language: string) {
        let url = '/api/stt/streams/interviews/' + videoId + '/subtitled/' + language;
        return Utils.fetchFromServer(url, "post")
    }

    static sendRTMP(videoId: string, language: string, rtmp: string) {
        let url = '/api/stt/streams/interviews/' + videoId + '/subtitled/' + language + "/stream_to_workplace";
        return Utils.fetchFromServer(url, "post", {"destination_rtmp_url": rtmp})
    }

    static getWordsInDictionary(category: string, dictId: string) {
        let url = '/api/stt/' + category.toLowerCase() + '/dictionaries/' + dictId;
        return Utils.fetchFromServer(url, "get")
    }

    static getWordsInDictionaryV2(category: string, dictId: string) {
        let url = '/api/stt/' + category.toLowerCase() + '/dictionaries/v2/' + dictId;
        return Utils.fetchFromServer(url, "get")
    }

    static async getVideoDetails(category: string, videoId: string) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId;
        let video = await Utils.fetchFromServer(url, "get");
        let videoData = video as model.VideoDetails;
        if(!("source_hls_url" in videoData)){
            throw new HTTPError(425);
        }
        url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/translations';
        let response = await Utils.fetchFromServer(url, "get");
        videoData.languagesTranslated = response.translations;
        url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/subtitled';
        response = await Utils.fetchFromServer(url, "get");
        videoData.languagesSubtitled = response.subtitled_streams;
        return videoData
    }

    static getSubtitledList(category: string, videoId: string){
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/subtitled';
        return Utils.fetchFromServer(url, "get");
    }

    static finalizeStream(videoId: string) {
        let url = '/api/stt/streams/interviews/' + videoId + '/finalize-stream';
        return Utils.fetchFromServer(url, 'get')
    }

    static filterVideosBy(filter: string) {
        if (!filter) {
            return this.getVideoList()
        }
        let url = '/api/stt/streams/interviews?expand&filter=' + encodeURIComponent(filter);
        return Utils.fetchFromServer(url, "get")
    }

    static saveMetadataVideoOnDB(videoMetadata: model.VideoDetails) {
        let url = '/api/stt/streams/interviews';
        return Utils.fetchFromServer(url, "post", videoMetadata)
    };


    static processUploadedVideo(videoId: any, body: any) {
        let url = '/api/storage/streams/interviews/' + videoId;
        return fetch(Utils.host + url, {
            headers: new Headers({
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt")
            }),
            method: "POST",
            body: body
        })
            .then((response: any) => {
                if (response.status >= 400) {
                    throw new HTTPError(response.status)
                }
                return response.json()
            })
    }


    static getVideoTranscriptionStatus(category: string, videoId: string, language: string) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/transcription/' + language + "/status";
        return Utils.fetchFromServer(url, "get").then((response: any) => {
            return response;
        });
    }

    static async getVideoTranscriptionWords(category: string, videoId: string) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/words_confidence';
        return await Utils.fetchFromServer(url, "get");
    }

    static async getVideoTranscriptionPhrases(category: string, videoId: string, language: string) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/phrases/' + language;
        return await Utils.fetchFromServer(url, "get");
    }

    static updatePhraseCustomText(category: string, videoId: string, language: string, custom_text: any) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + videoId + '/phrases/' + language;
        return Utils.fetchFromServer(url, "PATCH", custom_text);
    }

    static getVideoList() {
        let url = '/api/stt/streams/interviews?expand&not_void';
        return Utils.fetchFromServer(url, "get")
    }

    static updateWordsInDictionary(category: string, dictId: string, word: string) {
        let url = '/api/stt/' + category.toLowerCase() + '/dictionaries/' + dictId;
        return Utils.fetchFromServer(url, "post", {"word": word})
    }

    static starWordInDictionary(category: string, dictId: string, word: string
    ) {
        let url = '/api/stt/' + category.toLowerCase() + '/dictionaries/' + dictId + '/' + word;
        return Utils.fetchFromServer(url, "STAR")
    }

    static unstarWordInDictionary(category: string, dictId: string, word: string
    ) {
        let url = '/api/stt/' + category.toLowerCase() + '/dictionaries/' + dictId + '/' + word;
        return Utils.fetchFromServer(url, "UNSTAR")
    }

    static deleteWordFromDictionary(category: string, dictId: string, word: string
    ) {
        let url = '/api/stt/' + category.toLowerCase() + '/dictionaries/' + dictId + '/' + word;
        return Utils.fetchFromServer(url, "delete")
    }

    static deleteStream(category: string, id: string
    ) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + id;
        return Utils.fetchFromServer(url, "delete")
    }

    static deleteStreamSub(category: string, id: string, language: string
    ) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + id + '_sub-' + language;
        return Utils.fetchFromServer(url, "delete")
    }

    static updateStream(category: string, id: string, change: model.Change
    ) {
        let url = '/api/stt/streams/' + category.toLowerCase() + '/' + id;
        return Utils.fetchFromServer(url, "PATCH", change)
    }

    static fromTimestampToParsedDate(timestamp: number){
        let date = new Date(Number(timestamp));
        return date.toLocaleDateString("it-IT")
    }

    static capitalizeFirstLetter(word:string){
        return word.charAt(0).toUpperCase() + word.slice(1)
    }

}

Utils.init();